
import React, { useEffect, useState }  from "react";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll'
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
function scrollToTop() {
  scroll.scrollToTop();
}

library.add(faUser);
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");
  const { width, height } = useWindowDimensions();
  const [divisor, setDivisor] = React.useState(3);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [success, setSuccess] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => {setShow(true); setSuccess(false); setCollapseOpen(false);}

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("sending")
    console.log(name);
    console.log(address);
    console.log(phone);
    console.log(email);
    let params = {
      subject: 'Sign Request',
      replyTo: email,
      flag:'a',
      html: "Sign Request form submission <b>" + name + "</b><br/>" + address + "<br/>"+ phone+"<br/>"+ email+"<br/>",
    }

    await axios.post('https://hcdsb-strapi.herokuapp.com/send', params).catch(function (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        return
      }
    });
    setName('');
    setPhone('');
    setAddress('');
    setEmail('');
    setSuccess(true);

  };
  React.useEffect(() => {
    const changeColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setColor("bg-info");
        setDivisor(8)
      } else if (
        document.documentElement.scrollTop < 50 ||
        document.body.scrollTop < 50
      ) {
        setColor("navbar-transparent");
        if (width < 500){
          setDivisor(2);
        }
        else {
        setDivisor(3);
        }
        
      }
      if (height < 500){
        setDivisor(8);
      }

    };
    window.addEventListener("scroll", changeColor);
    if (width < 500) {
      setDivisor(2);
      
    }
    if (height < 500){
      setDivisor(8);
    }
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  },[width, height]);
  
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };


  return (
    
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      
      <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modalForm">
        <Modal.Header>
          <Modal.Title>Sign Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {success && 
          <h3 style={{color:'black'}}>Sign request sent! We'll be in touch soon!</h3>
        }
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={event => setName(event.target.value)}
                value={name}
                type="text"
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                              onChange={event => setEmail(event.target.value)}
                              value={email}
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                              onChange={event => setPhone(event.target.value)}
                              value={phone}
                type="phone"
                placeholder="(###) ###-####"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                              onChange={event => setAddress(event.target.value)}
                              value={address}
                type="text"
                placeholder="Address"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <div className="navbar-translate">
              
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
          <a href="_blank" target="_blank" onClick={scrollToTop}>
            <img
              alt="Alex Power Logo" width={width/divisor + 'px'}
              src={require("assets/img/APLogo.svg").default}
            />
          </a>
          </NavbarBrand>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Alex Power
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/Power4Trustee"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow me on Twitter"
              >
                <i className="fab fa-twitter" />
                <p className="d-lg-none d-xl-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/AlexPower4Trustee"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow me on Instagram"
              >
                <i className="fab fa-instagram" />
                <p className="d-lg-none d-xl-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <Link activeClass="active" to="AboutMe" spy={true} smooth={true} duration={500} >
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="#"
                >
                  <i className="fa fa-user d-lg-none d-xl-none" />
                  About Me
                </NavLink>
              </Link>
            </NavItem>
            <NavItem className="p-0">
              <Link activeClass="active" to="CoreValues" spy={true} smooth={true} duration={500} >
              
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="#"
                >
                  <i className="fa fa-heart d-lg-none d-xl-none" />
                  Core Values
                </NavLink>
              </Link>
            </NavItem>
            <NavItem className="p-0">
              <Link activeClass="active" to="Priorities" spy={true} smooth={true} duration={500} >
              
                <NavLink
                  data-placement="bottom"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="#"
                >
                  <i className="fa fa-heart d-lg-none d-xl-none" />
                  Priorities
                </NavLink>
              </Link>
            </NavItem>
            <NavItem className="p-0">
              <Link activeClass="active" to="Contact" spy={true} smooth={true} duration={500} >
              
                <NavLink
                  data-placement="bottom"
                  href="#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-envelope d-lg-none d-xl-none" />
                  Contact
                </NavLink>
              </Link>
            </NavItem>
            
              <NavLink
                  data-placement="bottom"
                  href="#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
              <Button
                className="nav-link d-lg-block"
                color="default"
                onClick={handleShow}
              >
                <i className="tim-icons icon-square-pin" /> Request Sign
              </Button>
              </NavLink>
            
            <NavItem className="p-0">
              <Link activeClass="active" to="Donate" spy={true} smooth={true} duration={500} >
              
              <NavLink
                  data-placement="bottom"
                  href="#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
              <Button
                className="nav-link d-lg-block"
                color="default"
                
                
              >
                <i className="tim-icons icon-money-coins" /> Donate
              </Button>
              </NavLink>
              </Link>
            </NavItem>

            
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
