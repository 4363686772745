import React from "react";
// react plugin used to create charts
// reactstrap components
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card, CardGroup
} from "reactstrap";
import axios from "axios";
import ReactGA from "react-ga4";


// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import InnerHTML from 'dangerously-set-html-content';


async function handleSubmit(e) {
  e.preventDefault();
  
  let params = {
    subject: 'Website Contact Form Submission',
    flag:'a',
    html: "New Contact from: <b>" + e.target.contactName.value + "</b><br/>"+e.target.contactEmail.value+"<br/><b>Website Contact Form Submission</b><br/><br/><b>"+e.target.contactMessage.value+"</b>",      
  }

  await axios.post('https://hcdsb-strapi.herokuapp.com/send', params).catch(function (error) {
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      return
    }
  });
  
  e.target.contactName.value = "";
  e.target.contactEmail.value = "";
  e.target.contactMessage.value = "";

};

export default function HomePage() {
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  },[]);

  var donation = '<script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe src="https://donorbox.org/embed/alex-power-hcdsb-trustee-oakville-wards-1-2-3" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>';


  const TRACKING_ID = "G-NEWQVZ0S8X"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

  return (
    <>
      <IndexNavbar />
      <div className="wrapper boxgrade">
        
        <div className="section">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          


          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          />
          <Container>
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  Representing Oakville Wards 1-2-3 as HCDSB Trustee
                </h1>
                <p className="text-white mb-3">
                I want to be part of the change that HCDSB needs. Our Catholic education is an institution to be valued. As Trustee, I will provide families the security of knowing that the board represents them, and in doing so, will act in the best interests of <u>all</u> children. I will conduct myself with professionalism and compassion. 
                </p>
                
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://twitter.com/Power4Trustee"
                      rel="noopener noreferrer"
                
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.instagram.com/AlexPower4Trustee"
                      rel="noopener noreferrer"
                    >
                    <i className="fab fa-instagram" />
                  </Button>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  width="150%"
                  src={require("assets/img/Alex_Headshot.jpeg").default}
                />
              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <section className="section section-lg" name="AboutMe">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  <img
                    alt="..."
                    className="img-fluid"
                    width="150%"
                    src={require("assets/img/element5-digital-OyCl7Y4y0Bk-unsplash.jpg").default}
                  />
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>
                      About Me
                    </h1>
                    <p>
                    My husband and I moved to Oakville in 2017 and we fell in love with the community and the people here. We have three children, all of whom attend HCDSB. They are my motivation for running for Trustee. Our Catholic schools provide amazing educational opportunities - our Trustees need to reflect these school communities.
                    </p>
                    <br/>
                    <p>
                      As an IB graduate and a family physician, I value the importance of a strong, public educational system. My two older children have had the opportunity to expand themselves within the French Immersion Programming at HCDSB. This is just one example of how our Catholic board provides numerous opportunities for our children to grow and prosper. 
                    </p>
                    <br/>
<p>
In my job as a family physician, I listen to people from all walks of life and hear all of their unique stories. I look forward to doing the same with the HCDSB community. 
</p>
<br/>
<p>
I feel strongly that community service is how we give back to and thank our communities. I have been part of the Parent Council for my children's school. In this past year I co-chaired the Council. I am also proud to be part of HCDSB’s Human Rights and Equity Advisory Council.                    </p>
   <br/>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        
        <section className="section section-lg" name="CoreValues">
          <section className="section">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path5.png").default}
          />
          <img
            alt="..."
            className="path3"
            src={require("assets/img/path2.png").default}
          />
          <Container>
            <Row className="justify-content-center" >
              <Col lg="12">
                <h1 className="text-center">Core Values</h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-trophy" />
                      </div>
                      <h4 className="info-title">Equity and Excellence</h4>
                      <hr className="line-primary" />
                      <p>
                        As Trustees we need to work collaboratively with staff and students to to ensure that all individuals are able to succeed. 
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-warning">
                        <i className="tim-icons icon-chat-33" />
                      </div>
                      <h4 className="info-title">Community Support and Advocacy</h4>
                      <hr className="line-warning" />
                      <p>
                        Trustees are also valuable resources for the community members to bring their concerns and ensuring that there is equity across the Board itself. 
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-badge" />
                      </div>
                      <h4 className="info-title">Member of HCDSB Human Rights & Equity Committee</h4>
                      <hr className="line-success" />
                      <p>
                        Member of a committee which exists to advise the board on how to better address matters of equity with marginalized communities.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-danger">
                        <i className="tim-icons icon-heart-2" />
                      </div>
                      <h4 className="info-title">Compassion and Integrity </h4>
                      <hr className="line-danger" />
                      <p>
                        Aim to bring a sense of professionalism and compassion back to the board and return it operating in a functional way. 
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        </section>
        <section className="section section-lg" name="Priorities">
          <section className="section">
          <Container>
            <Row className="justify-content-center" >
              <Col lg="12">
                <h1 className="text-center">Priorities</h1>
                <CardGroup>
                    <Card body style={{backgroundColor: 'rgb(129, 176, 220,0.1)', boxShadow:'3px 5px #888888'}}>
                          <h3 className="info-title">Student Achievement & Success</h3>
                      <p>
                        <ul>
                          <li>
                          Help our children catch up and close learning gaps caused by the COVID-19 pandemic, by maintaining capped class sizes and providing additional resources for tutoring.
                          </li>
                          <li>
                          Promoting academic excellence by supporting our existing IB, AP, STEAM and French Immersion programs, including looking for opportunities to expand these programs to meet our community needs.
                          </li>
                          <li>
                          Ensuring the success of our Special Education Programming.
                          </li>
                        </ul>
                      </p>
                      </Card>
                      <Card body style={{backgroundColor: 'rgb(129, 176, 220,0.1)', boxShadow:'3px 5px #888888'}}>
                      <h3 className="info-title">Promoting Safe Schools</h3>
                      
                      <p>
                        <ul>
                          <li>
                          The promotion of equity is a cornerstone of my platform. I will ensure there is a clear avenue for students, staff, and parents to report instances of abuse and transparency in the handling of these complaints.
                          </li>
                          <li>
                          Expanding the relationship, cooperation, and engagement with community groups and professionals trained in areas and programming related to student achievement, safety, mental health, and well-being.  
                          </li>
                        </ul>
                      </p>
                      </Card>
                      <Card body style={{backgroundColor: 'rgb(129, 176, 220,0.1)', boxShadow:'3px 5px #888888'}}>
                      <h3 className="info-title">Reflective Educational Programming</h3>
                      <p>
                        <ul>
                          <li>
                          Support programming that ensures our students see themselves represented.
                          </li>
                          <li>
                          Taking the pressure off our educators by engaging the community resources to help support and run Student led groups (for example, Black Excellence, GSA groups)

                          </li>
                        </ul>
                      </p>
                      </Card>
                    
                  
                </CardGroup>
              </Col>
            </Row>
          </Container>
          </section>
        </section>
        <section className="section section-lg section-safe" name="Contact">
          <img
            alt="..."
            className="path"
            src={require("assets/img/path5.png").default}
          />
          <Container>
          <Row className="justify-content-lg-between text-center space-between">
              <Col lg="5" md="12">
            
            <h1 className="text-center">Contact Me</h1>
            <p>Do you have questions, comments or concerns?</p>
            <br/>
            <p>Want to help with the campaign?</p>
            <br/>
            <p>I'd love to hear from you!</p>
            <br/>
            <Form onSubmit={handleSubmit}>
            <InputGroup className="input-group-grey" bsSize="lg">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="contactName"
                placeholder="Name"
                type="text"
              />
            </InputGroup>
            <InputGroup className="input-group-grey" bsSize="lg">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-email-85" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="contactEmail"
                placeholder="Email"
                type="text"
              />
            </InputGroup>
            <InputGroup className="input-group-grey" bsSize="lg">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-chat-33" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="contactMessage"
                placeholder="Message"
                type="textarea"
                rows="20"
              />
            </InputGroup>
              <Button
                      className=" btn-simple btn-round btn-neutral"
                      
                type="submit"
                    >
                    Send
                  </Button>
            </Form>
                      
            
</Col>
<Col lg="5" md="12" name="Donate">
            
<h1 className="text-center">Donate to Alex Power Campaign</h1>

            <br/>
            <div className="flex justify-content-center text-center">
                <InnerHTML html={donation} />
            </div>
</Col>
</Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
