
import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

const getCurrentYear = () => {
  return new Date().getFullYear();
}
export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Alex Power</h1>
            <h3>HCDSB Candidate - Oakville 1-2-3</h3>
          </Col>
          <Col md="4">
            
          </Col>
          <Col md="4">
            <h3 className="title">Follow me:</h3>
            <div className="btn-wrapper profile">
            <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://twitter.com/Power4Trustee"
                      rel="noopener noreferrer"
                
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.instagram.com/AlexPower4Trustee"
                      rel="noopener noreferrer"
                    >
                    <i className="fab fa-instagram" />
                  </Button>
              
            </div>
          </Col>
        </Row>
        <Row>
        <Col xs="12">
        <div className="copyright">
          <p >© {getCurrentYear()} - Alex Power Campaign -  All Right Reserved</p>
          </div>
        </Col>
        </Row>

      </Container>
    </footer>
  );
}
